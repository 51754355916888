<template>
  <div id="app">
    <router-view>

    </router-view>

  </div>
</template>

<script>


export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
@font-face {
  font-family: "Poppinslight";
  src: local("Poppinslight"),
  url(./assets/fonts/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppinsbold";
  src: local("Poppinsbold"),
  url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppinsblack";
  src: local("Poppinsblack"),
  url(./assets/fonts/Poppins-Black.ttf) format("truetype");
}

@font-face {
  font-family: "Poppinsblackitalic";
  src: local("Poppinsblackitalic"),
  url(./assets/fonts/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppinsbolditalic";
  src: local("Poppinsbolditalic"),
  url(./assets/fonts/Poppins-BoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppinsextralight";
  src: local("Poppinsextralight"),
  url(./assets/fonts/Poppins-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Poppinsextralightitalic";
  src: local("Poppinsextralightitalic"),
  url(./assets/fonts/Poppins-ExtraLightItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppinslight";
  src: local("Poppinslight"),
  url(./assets/fonts/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Poppinslightitalic";
  src: local("Poppinslightitalic"),
  url(./assets/fonts/Poppins-LightItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppinsmedium";
  src: local("Poppinsmedium"),
  url(./assets/fonts/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Poppinsmediumitalic";
  src: local("Poppinsmediumitalic"),
  url(./assets/fonts/Poppins-MediumItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppinsregular";
  src: local("Poppinsregular"),
  url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}


@font-face {
  font-family: "Poppinssemibold";
  src: local("Poppinssemibold"),
  url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppinssemibolditalic";
  src: local("Poppinssemibolditalic"),
  url(./assets/fonts/Poppins-SemiBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Poppinsultralight";
  src: local("Poppinsultralight"),
  url(./assets/fonts/Poppins-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Poppinsultralightitalic";
  src: local("Poppinsultralightitalic"),
  url(./assets/fonts/Poppins-ThinItalic.ttf) format("truetype");
}
#app {
  display: grid;
  background-color: white;
  font-family:  sans-serif;

}

</style>
